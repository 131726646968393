import './App.css';

function App() {
  return (
    <div className="App">
      <embed src="https://form.typeform.com/to/Eyj8i99y" width={'100%'} height={950} />
    </div>
  );
}

export default App;
